import React from "react";
import classnames from "classnames";
import { PayTypeMap } from "@core";
import { Button, Image, Helper, Link, Loading } from "@components";
import { paymentMethodMap, orderDepositLimit } from "@config/enum";
import { simpleTimer } from "@utils/timer";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
/**
 * [Component] payment page form
 */
const PaymentForm = (props) => {
  const {
    order,
    submitPay,
    paymentMethod,
    setPaymentMethod,
    payType,
    setPayType,
    money,
    balanceStr,
    loadingBalance,
    loadingPayment,
  } = props;
  const parseClass = (kind) => {
    return {
      wrapper: classnames(
        "rounded-lg p-4 md:px-6 md:py-5 cursor-pointer border mb-5",
        {
          "border-fish-primary text-white bg-fish-primary": payType === kind,
          "border-blue-gray-200 bg-white": payType !== kind,
        }
      ),
      radio: classnames("w-5 h-5 rounded-full border-6 mt-1", {
        "bg-fish-primary border-white": payType === kind,
        "bg-gray-200 border-blue-gray-200": payType !== kind,
      }),
      subTitle: classnames("font-normal", {
        "text-blue-gray-500": payType !== kind,
        "text-white": payType === kind,
      }),
    };
  };

  return (
    <React.Fragment>
      <div className="shadow rounded-lg md:flex overflow-hidden">
        <div className="flex-1 bg-white p-5 md:p-10">
          <div className="text-xl font-bold">
            {`${get(order, "title", "")} ${get(
              order,
              "order.market.season.years",
              ""
            )}`}{" "}
            Season
          </div>
          <div className="text-xl font-bold">
            {get(order, "order.market.isSaleByUnit", false)
              ? `${order.unitsStr} · `
              : null}
            {order.amountStr}
          </div>
          <div className="mt-8 hidden">
            {order.depositPaid ? null : (
              <React.Fragment>
                <div
                  className={parseClass("instant").wrapper}
                  onClick={() => {
                    setPayType(PayTypeMap.instant);
                  }}
                >
                  <div className="flex">
                    <div className={parseClass("instant").radio}></div>
                    <div className="flex-1 font-bold px-4 md:px-6">
                      Instant Online Payment
                    </div>
                    <div className="font-bold">
                      {payType === PayTypeMap.instant
                        ? money.currentPaymentTotal.format()
                        : money.instantPaymentTotal.format()}
                    </div>
                  </div>
                </div>
                {order.subTotalWithGst < orderDepositLimit ||
                order.useHoldingFee ||
                order.isAuctionOrder ? null : (
                  <div
                    className={parseClass("hold_fee").wrapper}
                    onClick={() => {
                      setPayType(PayTypeMap.holdFee);
                    }}
                  >
                    <div className="flex">
                      <div className={parseClass("hold_fee").radio}></div>
                      <div className="flex-1 font-bold px-4 md:px-6">
                        <div className="flex items-center">
                          <div className="mr-2">Holding Fee</div>
                          <div className="text-blue-gray-800">
                            <Helper
                              content={`A ${order.holdingFeePercentageStr} holding fee will be refunded to your credit card once the full payment has been made.`}
                            />
                          </div>
                        </div>
                        <div className={parseClass("hold_fee").subTitle}>
                          Pay a {order.holdingFeePercentageStr} holding fee now,
                          Complete the balance payment within{" "}
                          {simpleTimer(order.holdingExpirationDur)}
                        </div>
                      </div>
                      <div className="font-bold text-right">
                        {payType === PayTypeMap.holdFee
                          ? money.currentPaymentTotal.format()
                          : money.holdPaymentTotal.format()}
                      </div>
                    </div>
                  </div>
                )}
                {/* <div
                  className={parseClass("deposit").wrapper}
                  onClick={() => {
                    setPayType(PayTypeMap.deposit);
                  }}
                >
                  <div className="flex">
                    <div className={parseClass("deposit").radio}></div>
                    <div className="flex-1 font-bold px-4 md:px-6">
                      <div>Pay Later</div>
                      <div className={parseClass("deposit").subTitle}>
                        {order.depositPercentageStr} deposit now, remainder in{" "}
                        {simpleTimer(order.defaultExpirationDur)}
                      </div>
                    </div>
                    <div className="font-bold text-right">
                      <div>{payType === PayTypeMap.deposit ? money.currentPaymentTotal.format() : money.depositPaymentTotal.format()}</div>
                      <div className={parseClass("deposit").subTitle}>Deposit now</div>
                    </div>
                  </div>
                </div> */}
              </React.Fragment>
            )}

            {!order.depositPaid ? null : (
              <div
                className={parseClass("balance").wrapper}
                onClick={() => {
                  setPayType(PayTypeMap.balance);
                }}
              >
                <div className="flex">
                  <div className={parseClass("balance").radio}></div>
                  <div className="flex-1 font-bold px-4 md:px-6">
                    Outstanding Balance Payment
                  </div>
                  <div className="font-bold">
                    {payType === PayTypeMap.balance
                      ? money.currentPaymentTotal.format()
                      : money.balancePaymentTotal.format()}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-8">
            <div className="font-semibold mb-2 hidden">
              {payType
                .split("_")
                .map((o) => capitalize(o))
                .join(" ")}
            </div>
            {payType === PayTypeMap.instant ? (
              <React.Fragment>
                <div className="flex justify-between py-4">
                  <div className="text-sm">Subtotal</div>
                  <div className="font-semibold">{order.subTotalStr}</div>
                </div>
                <div className="flex justify-between py-4 border-t border-blue-gray-200">
                  <div className="text-sm">GST</div>
                  <div className="font-semibold">{order.gstStr}</div>
                </div>
                {order.serviceFeeWithGst &&
                Math.abs(order.serviceFeeWithGst) > 0 ? (
                  <div className="flex justify-between py-4 border-t border-blue-gray-200">
                    <div className="text-sm">Service Fee Inc. GST</div>
                    <div className="font-semibold">
                      {order.serviceFeeWithGstStr}
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}
            {payType === PayTypeMap.holdFee ? (
              <div className="flex justify-between py-4">
                <div className="text-sm">Holding Fee</div>
                <div className="font-semibold">
                  {money.holdPaymentTotal.format()}
                </div>
              </div>
            ) : null}
            {payType === PayTypeMap.deposit ? (
              <div className="flex justify-between py-4">
                <div className="text-sm">Deposit</div>
                <div className="font-semibold">
                  {money.depositPaymentTotal.format()}
                </div>
              </div>
            ) : null}

            {payType === PayTypeMap.balance ? (
              <div className="flex justify-between py-4">
                <div className="text-sm">Outstanding Balance</div>
                <div className="font-semibold">
                  {money.balancePaymentTotal.format()}
                </div>
              </div>
            ) : null}
            {money && money.cardProcessingFeeWithGst.value() > 0 ? (
              <div className="flex justify-between py-4 border-t border-blue-gray-200">
                <div className="text-sm">Card Processing Fee Inc. GST</div>
                <div className="font-semibold">
                  {money.cardProcessingFeeWithGst.format()}
                </div>
              </div>
            ) : null}

            <div className="flex justify-between py-4 border-t border-blue-gray-800">
              <div className="text-sm">
                {payType === PayTypeMap.balance
                  ? "Total Balance"
                  : "Total Payment"}
              </div>
              <div className="font-semibold">
                {money.currentPaymentTotal.format()}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-gray-50 border-t border-blue-gray-200 p-5 md:p-10">
          <div className="text-xl font-semibold mb-6">
            Please choose your payment method
          </div>
          <label
            htmlFor="paymentRadioWallet"
            className="block bg-white border border-blue-gray-200 md:rounded-md p-5-tw md:px-8 md:py-6 mb-4 cursor-pointer"
          >
            <div className="">
              <div className="">
                <div className="flex justify-between">
                  <div className="flex-1 flex items-center">
                    <input
                      id="paymentRadioWallet"
                      type="radio"
                      name="payment"
                      className="flex-shrink-0 focus:ring-blue-500 h-4 w-4 text-fish-primary border-blue-gray-300"
                      value={paymentMethodMap.wallet}
                      onChange={(evt) => {
                        const value = evt.target.value;
                        if (value) {
                          setPaymentMethod(value);
                        }
                      }}
                      checked={paymentMethod === paymentMethodMap.wallet}
                    />
                    <div className="ml-3 block text-xl font-bold text-blue-gray-700">
                      Wallet Balance
                    </div>
                  </div>
                  <div className="font-bold text-lg text-right">
                    {loadingBalance ? (
                      <Loading size="sm" spacing="p-0" />
                    ) : (
                      balanceStr
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-sm text-blue-gray-500 ml-8">
                    No proceesing fee
                  </div>
                  <div>
                    <Link
                      to="/account/topup"
                      className="text-link hover:text-link text-sm"
                    >
                      Top up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </label>

          {payType === PayTypeMap.holdFee ? null : (
            <label
              htmlFor="paymentRadioPoli"
              className="block bg-white border border-blue-gray-200 md:rounded-md p-5-tw md:px-8 md:py-6 mb-4 cursor-pointer"
            >
              <div className="">
                <div className="flex flex-wrap">
                  <div className="flex-1 flex items-center">
                    <input
                      id="paymentRadioPoli"
                      type="radio"
                      name="payment"
                      className="flex-shrink-0 focus:ring-blue-500 h-4 w-4 text-fish-primary border-blue-gray-300"
                      value={paymentMethodMap.poli}
                      onChange={(evt) => {
                        const value = evt.target.value;
                        if (value) {
                          setPaymentMethod(value);
                        }
                      }}
                      checked={paymentMethod === paymentMethodMap.poli}
                    />
                    <div className="ml-3 block text-xl font-bold text-blue-gray-700">
                      POLi
                    </div>
                  </div>
                  <Image source="deposit/poli" className="hidden md:block w-26" />
                </div>
                <div className="text-sm text-blue-gray-600 md:ml-8 flex items-center whitespace-no-wrap">
                  No proceesing fee
                </div>
              </div>
            </label>
          )}

          <label
            htmlFor="paymentRadioStripe"
            className="block bg-white border border-blue-gray-200 md:rounded-md p-5-tw md:px-8 md:py-6 mb-4 cursor-pointer"
          >
            <div className="">
              <div className="flex flex-wrap">
                <div className="flex-1 flex items-center">
                  <input
                    id="paymentRadioStripe"
                    type="radio"
                    name="payment"
                    className="flex-shrink-0 focus:ring-blue-500 h-4 w-4 text-fish-primary border-blue-gray-300"
                    value={paymentMethodMap.stripe}
                    onChange={(evt) => {
                      const value = evt.target.value;
                      if (value) {
                        setPaymentMethod(value);
                      }
                    }}
                    checked={paymentMethod === paymentMethodMap.stripe}
                  />
                  <div className="ml-3 block text-xl font-bold text-blue-gray-700">
                    Credit Card
                  </div>
                </div>
                <svg
                  className="hidden md:block"
                  width="130"
                  height="30"
                  viewBox="0 0 65 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="47.5" cy="7.5" r="7.5" fill="#D82E2B" />
                  <circle cx="56.6072" cy="7.5" r="7.5" fill="#EAA140" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M52.0537 13.5271C53.8449 12.1565 55.0002 9.99671 55.0002 7.56712C55.0002 5.13753 53.8449 2.97776 52.0537 1.60718C50.2626 2.97776 49.1073 5.13753 49.1073 7.56712C49.1073 9.99671 50.2626 12.1565 52.0537 13.5271Z"
                    fill="#EC692C"
                  />
                  <path
                    d="M23.2602 3.40849C22.5157 3.13418 21.7306 2.99383 20.9392 3.00021C18.3805 3.00021 16.5724 4.31757 16.5599 6.20269C16.5443 7.59022 17.8456 8.36852 18.8278 8.83422C19.8381 9.30949 20.176 9.60932 20.1728 10.0367C20.1666 10.6843 19.3658 10.9777 18.6244 10.9777C17.6016 10.9777 17.0416 10.831 16.1846 10.4674L15.8655 10.3174L15.5026 12.4896C16.1251 12.7544 17.245 12.9809 18.4023 13C21.1238 13 22.9036 11.6922 22.9224 9.68268C22.9443 8.57585 22.2436 7.73695 20.7609 7.04797C19.8631 6.59822 19.3032 6.29839 19.3032 5.84225C19.3032 5.44035 19.7818 5.00974 20.7828 5.00974C21.4491 4.99379 22.1091 5.12138 22.7222 5.37974L22.963 5.48819L23.3259 3.38936L23.2602 3.40849ZM29.9042 3.17564H27.9054C27.2829 3.17564 26.8168 3.34789 26.5447 3.97945L22.6972 12.8628H25.4186L25.9629 11.4083L29.2817 11.4115C29.363 11.7528 29.6008 12.8628 29.6008 12.8628H32L29.9042 3.17564ZM12.8813 3.0959H15.4714L13.851 12.7895H11.2579L12.8813 3.0959ZM6.29365 8.4355L6.56266 9.78157L9.09638 3.17564H11.8428L7.76383 12.8501H5.02366L2.78397 4.65568C2.74643 4.52171 2.66197 4.40369 2.54311 4.33351C1.7392 3.90609 0.885239 3.58393 0 3.37341L0.0344086 3.16926H4.21036C4.77654 3.19159 5.23011 3.37341 5.38651 3.98902L6.29677 8.43869V8.4355H6.29365ZM26.7042 9.42432L27.7365 6.71305C27.7239 6.74176 27.9492 6.15166 28.0805 5.78803L28.2588 6.62374L28.8563 9.42113L26.7042 9.42432Z"
                    fill="#2563EB"
                  />
                </svg>
              </div>
              <div className="text-sm text-blue-gray-600 md:ml-8 flex items-center whitespace-no-wrap">
                Processing fee of $0.30 + 1.75% plus GST
              </div>
            </div>
          </label>
          <div className="bg-white border border-blue-gray-200 md:rounded-md p-5-tw md:px-8 md:py-6">
            <div className="">
              <div className="font-normal text-lg text-blue-gray-500 mb-2">
                Total Payment
              </div>
              <div className="mt-2 text-2xl font-bold leading-tight md:text-3xl">
                {money.currentPaymentTotal.format()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:flex justify-end items-center space-y-5 md:space-y-0 my-6">
        <Button
          size="xl"
          color="fish-primary"
          textColor="white"
          className="hover:bg-fish-primary-700"
          disabled={loadingPayment || order.isExpired}
          loading={loadingPayment}
          onClick={() => {
            submitPay();
          }}
        >
          Continue with payment
        </Button>
      </div>
    </React.Fragment>
  );
};

export default PaymentForm;
